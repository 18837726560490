const videoHolder: HTMLDivElement = document.querySelector('.video');
const startbutton: HTMLButtonElement = document.querySelector('.activate');

async function getCameras() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const cameras = devices.filter((device) => device.kind === 'videoinput');
  return cameras;
}
function startStopCamera(this: HTMLElement) {
  const id: string = this.dataset.cameraId;

  const video: HTMLVideoElement = document.querySelector(
    `video[data-camera-id="${id}"]`
  );

  if (video.srcObject) {
    console.log('has src');

    const stream: MediaStream = <MediaStream>video.srcObject;
    const tracks: MediaStreamTrack[] = stream.getTracks();
    tracks.forEach(function (track) {
      track.stop();
    });
    video.srcObject = null;
    this.innerHTML = 'Start Camera';
  } else {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: { deviceId: { exact: id } },
      })
      .then((mediaStream) => {
        if (video) {
          video.srcObject = mediaStream;
          video.onloadedmetadata = function () {
            video.play().then(
              () => {},
              () => {}
            );
          };
          this.innerHTML = 'Stop Camera';
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }
}

function createVideoElementFromCamera(camera: MediaDeviceInfo) {
  const markup = `
    <div class="camera">
      <video data-camera-id="${camera.deviceId}" playsinline muted></video>
      <p>${camera.label}</p>
      <button data-camera-id="${camera.deviceId}" class="start-camera">Start Camera</button>
    </div>
  `;
  const fragment = document.createRange().createContextualFragment(markup);
  return fragment;
}

async function requestIntialAccess() {
  videoHolder.innerHTML = '';
  await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: true,
  });

  const cameras = await getCameras();

  // Create video elements for each
  const videoFragment: DocumentFragment[] = cameras.map(
    createVideoElementFromCamera
  );
  // dump them into the DOM
  videoFragment.forEach((el) => videoHolder.append(el));

  document.querySelectorAll('.start-camera').forEach((button) => {
    button.addEventListener('click', startStopCamera);
  });
}

startbutton.addEventListener('click', requestIntialAccess);

if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
  requestIntialAccess().then(
    () => {},
    () => {}
  );
}
